{
  "name": "plasma-games-angular",
  "author": {
    "name": "Plasma Games",
    "url": "https://play.plasma.games"
  },
  "version": "8.12.0",
  "engines": {
    "npm": ">=10.0.0 <11.0.0",
    "node": ">=18.17.0 <19.0.0"
  },
  "scripts": {
    "start": "node server.js",
    "build": "node scripts/heroku-build",
    "build:debug": "npm run build:pug && npm run ng -- build --prod --source-map --build-optimizer=true && npm run build:version",
    "build:pug": "node scripts/build-pug.js",
    "build:version": "node --experimental-json-modules scripts/version.js",
    "build:github": "node scripts/github-build.js",
    "bundle-report": "webpack-bundle-analyzer dist/plasma-games-angular/stats.json",
    "cy": "cypress open",
    "cy:clear-cache": "cypress cache clear",
    "cy:report": "node scripts/cy-report.js",
    "cy:serve": "static-server ./cypress/reports/html 7200",
    "docker:build": "node scripts/docker/docker-build.js",
    "docker:run": "node scripts/docker/docker-run.js",
    "e2e": "npm run ng -- e2e --port 6200",
    "generate:component": "ng generate @sbpro/ng:component",
    "generate:directive": "ng generate @sbpro/ng:directive",
    "generate:module": "ng generate @sbpro/ng:module",
    "generate:service": "ng generate @sbpro/ng:service",
    "lint": "npm run ng -- lint",
    "lint:fix": "npm run ng -- lint --fix",
    "ng": "cross-env NODE_OPTIONS=--max_old_space_size=4096 ./node_modules/.bin/ng",
    "serve": "static-server dist/plasma-games-angular",
    "serve:coverage": "static-server ./coverage/plasma-games-angular 9753",
    "local-start": "node scripts/start.js",
    "start-ubuntu": "node scripts/start-ubuntu.js",
    "test": "npm run ng -- test --watch=false --code-coverage --browsers=ChromeHeadlessNoSandbox",
    "test:dev": "node scripts/test-dev.js",
    "version": "node --experimental-json-modules scripts/version",
    "codegen": "graphql-codegen --config codegen.yml",
    "debug": "node scripts/render-pug.js",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org plasma-games --project javascript-angular ./dist/plasma-games-angular && sentry-cli sourcemaps upload --org plasma-games --project javascript-angular ./dist/plasma-games-angular"
  },
  "private": true,
  "type": "module",
  "overrides": {
    "@cypress/request": "3.0.0"
  },
  "dependencies": {
    "@amplitude/analytics-browser": "2.10.0",
    "@angular/animations": "17.3.12",
    "@angular/cdk": "17.3.10",
    "@angular/common": "17.3.12",
    "@angular/compiler": "17.3.12",
    "@angular/core": "17.3.12",
    "@angular/forms": "17.3.12",
    "@angular/localize": "17.3.12",
    "@angular/platform-browser": "17.3.12",
    "@angular/platform-browser-dynamic": "17.3.12",
    "@angular/router": "17.3.12",
    "@apollo/client": "3.9.8",
    "@awesome.me/kit-55ca5fc4e1": "1.0.290",
    "@fortawesome/angular-fontawesome": "0.14.1",
    "@fortawesome/fontawesome-svg-core": "6.6.0",
    "@fortawesome/free-brands-svg-icons": "6.6.0",
    "@fortawesome/free-regular-svg-icons": "6.6.0",
    "@fortawesome/free-solid-svg-icons": "6.6.0",
    "@fortawesome/pro-duotone-svg-icons": "6.6.0",
    "@fortawesome/pro-light-svg-icons": "6.6.0",
    "@fortawesome/pro-regular-svg-icons": "6.6.0",
    "@fortawesome/pro-solid-svg-icons": "6.6.0",
    "@ion-phaser/core": "1.3.0",
    "@json2csv/plainjs": "^7.0.6",
    "@kolkov/angular-editor": "3.0.0-beta.0",
    "@ng-bootstrap/ng-bootstrap": "16.0.0",
    "@ngxs/devtools-plugin": "3.8.2",
    "@ngxs/logger-plugin": "3.8.2",
    "@ngxs/router-plugin": "3.8.2",
    "@ngxs/storage-plugin": "3.8.2",
    "@ngxs/store": "3.8.2",
    "@popperjs/core": "2.11.8",
    "@prettier/plugin-pug": "3.0.0",
    "@sentry/angular-ivy": "7.120.1",
    "@sentry/integrations": "7.120.1",
    "angular-feather": "6.5.1",
    "angular-google-tag-manager": "1.9.0",
    "aos": "2.3.4",
    "apollo-angular": "6.0.0",
    "chart.js": "3.9.1",
    "copy-to-clipboard": "3.3.3",
    "cross-env": "7.0.3",
    "date-fns": "3.6.0",
    "device-detector-js": "3.0.3",
    "express": "4.21.2",
    "graphql": "16.9.0",
    "graphql-tools": "9.0.1",
    "jquery": "3.7.1",
    "just-compare": "2.3.0",
    "lity": "2.4.1",
    "lodash": "4.17.21",
    "mdb-angular-ui-kit": "git+https://oath2:qsZxKa4239R5ivzJFBRH@git.mdbootstrap.com/mdb/angular/mdb5/prd/mdb5-angular-ui-kit-pro-essential.git",
    "moment": "2.30.1",
    "ngx-clipboard": "16.0.0",
    "ngx-cookie-service": "17.1.0",
    "ngx-device-detector": "7.0.0",
    "ngx-easy-table": "15.6.0",
    "ngx-quill": "25.3.2",
    "ngx-spinner": "16.0.2",
    "ngx-ui-switch": "15.0.0",
    "ngx-ui-tour-core": "12.0.1",
    "ngx-ui-tour-ng-bootstrap": "14.0.2",
    "ngxs-reset-plugin": "3.0.0",
    "object-hash": "3.0.0",
    "phaser": "3.55.2",
    "prismjs": "1.29.0",
    "rxjs": "7.8.1",
    "scriptjs": "^2.5.9",
    "subsink": "^1.0.2",
    "tslib": "2.6.3",
    "uuid": "8.3.2",
    "webpack-bundle-analyzer": "4.10.2",
    "zone.js": "0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "17.3.11",
    "@angular/cli": "17.3.11",
    "@angular/compiler-cli": "17.3.12",
    "@angular/language-service": "17.3.12",
    "@cypress/webpack-preprocessor": "6.0.2",
    "@graphql-codegen/cli": "5.0.2",
    "@graphql-codegen/introspection": "4.0.3",
    "@graphql-codegen/typescript": "4.0.9",
    "@graphql-codegen/typescript-apollo-angular": "4.0.0",
    "@graphql-codegen/typescript-operations": "4.2.3",
    "@inip/static-server": "1.0.1",
    "@sbpro/ng": "3.1.0",
    "@types/aos": "3.0.7",
    "@types/chart.js": "2.9.41",
    "@types/cypress": "1.1.6",
    "@types/jasmine": "5.1.4",
    "@types/jasminewd2": "2.0.13",
    "@types/lodash": "4.17.7",
    "@types/mocha": "10.0.6",
    "@types/node": "20.11.30",
    "@types/node-fetch": "2.6.11",
    "@types/object-hash": "3.0.6",
    "@types/prismjs": "1.26.3",
    "@types/quill": "1.3.10",
    "@types/scriptjs": "0.0.5",
    "@types/uuid": "9.0.8",
    "@types/webpack-env": "1.18.4",
    "bootstrap": "5.2.3",
    "chokidar": "3.6.0",
    "concurrently": "8.2.2",
    "cross-env": "7.0.3",
    "cypress": "13.16.0",
    "cypress-file-upload": "5.0.8",
    "cypress-multi-reporters": "2.0.4",
    "dotenv": "16.4.5",
    "jasmine-core": "5.2.0",
    "jasmine-spec-reporter": "7.0.0",
    "karma": "6.4.4",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage-istanbul-reporter": "3.0.3",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "mocha": "10.7.3",
    "mochawesome": "7.1.3",
    "mochawesome-json-to-md": "^1.3.5",
    "mochawesome-merge": "4.3.0",
    "mochawesome-report-generator": "6.2.0",
    "p-limit": "6.1.0",
    "prettier": "3.3.3",
    "pug": "3.0.3",
    "pug-lint": "2.7.0",
    "shelljs": "0.8.5",
    "ts-loader": "9.5.1",
    "ts-node": "10.9.2",
    "typescript": "5.3.3",
    "upath": "2.0.1",
    "workerpool": "6.5.1"
  }
}
